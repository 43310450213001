import "@mdi/font/css/materialdesignicons.min.css";
import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    themes: {
      light: {
        primary: "#7986CB",
        secondary: "#E8EAF6",
        accept: "#42802e",
        error: "#EF9A9A",
        // warning: '#3f51b5',
        // info: '#d8d96e',
        success: "#C8E6C9",
      },
    },
  },
  // theme: {
  // 	themes: {
  // 		light: {
  // 			primary: '#E53935',
  // 			success: '#1ee',
  // 			secondary: '#FFCDD2',
  // 			accent: '#3F51B5'
  // 		}
  // 	}
  // }
});

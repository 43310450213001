import axios from "axios";
import { loadAuthData, saveAuthData } from "@/storage/storage";

const URL = "/api"; // process.env.VUE_APP_SERVER_HOST;
const URL_AUTH = process.env.VUE_APP_AUTH_SERVER_HOST;

export const STATUS_UNAUTHORIZED = 401;
export const STATUS_OK = 200;
export const STATUS_INTERNAL_SERVER_ERROR = 500;

const checkError = (error) => {
  console.error(error);

  if (!error.response) {
    return {
      status: STATUS_INTERNAL_SERVER_ERROR,
      message: "Непредвиденная ошибка сервера",
    };
  }

  const response = error.response;

  const result = {
    status: STATUS_INTERNAL_SERVER_ERROR,
  };
  if (response.status) {
    result.status = response.status;
  }
  if (
    response.headers &&
    response.headers["content-type"] === "application/json"
  ) {
    result.message = error.response.data.message;
  } else {
    result.message = "Непредвиденная ошибка сервера";
  }
  return result;
};

export const post = async (url, data = null) => {
  try {
    checkToken();
    const resp = await axios.post(URL + url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loadAuthData().token}`,
      },
    });
    return { data: resp.data, status: resp.status };
  } catch (error) {
    return checkError(error);
  }
};

export const generateFile = async (url, data = null) => {
  try {
    checkToken();
    const {
      status,
      message,
      data: respData,
      headers: respHeaders,
    } = await axios.post(URL + url, data, {
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loadAuthData().token}`,
      },
    });

    console.log(respHeaders);
    console.log(respHeaders["content-type"]);
    return {
      status,
      message,
      data: respData,
      type: respHeaders["content-type"],
    };
  } catch (error) {
    return checkError(error);
  }
};

export const get = async (url, params = null, options = {}) => {
  try {
    checkToken();

    const resp = await axios.get(URL + url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loadAuthData().token}`,
      },
      params,
      ...options,
    });
    return { data: resp.data, status: resp.status };
  } catch (error) {
    return checkError(error);
  }
};

export const remove = async (url, params = null) => {
  try {
    checkToken();
    const resp = await axios.delete(URL + url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loadAuthData().token}`,
      },
      params,
    });
    return { data: resp.data, status: resp.status };
  } catch (error) {
    return checkError(error);
  }
};

export const put = async (url, data) => {
  try {
    checkToken();
    const resp = await axios.put(URL + url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loadAuthData().token}`,
      },
    });
    return { data: resp.data, status: resp.status };
  } catch (error) {
    return checkError(error);
  }
};

export const patch = async (url, data) => {
  try {
    checkToken();
    const resp = await axios.patch(URL + url, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${loadAuthData().token}`,
      },
    });

    return { data: resp.data, status: resp.status };
  } catch (error) {
    return checkError(error);
  }
};

export const checkToken = () => {
  const jwtData = loadAuthData();
  if (
    jwtData &&
    jwtData.token &&
    jwtData.expires &&
    Date.parse(jwtData.expires.replaceAll("%3A", ":").replace("%20", "+")) >
      new Date()
  ) {
    return true;
  }

  const query = parseQuery(location.search);
  if (!query || !query.token || !query.expires) {
    window.location.replace(
      `${URL_AUTH}?callback=${location.origin}${location.pathname}`
    );
    return;
  }

  saveAuthData(query);
  location.href = location.origin + location.pathname;
};

const parseQuery = (search) => {
  if (!search) {
    return null;
  }

  search = search.substring(1);
  const query = {};
  search.split("&").forEach((el) => {
    const pair = el.split("=");
    const key = pair[0];
    query[key] = pair[1];
  });

  return query;
};

export const login = () => {
  window.location.replace(
    `${URL_AUTH}?callback=${location.origin}${location.pathname}`
  );
};

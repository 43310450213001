<template>
  <v-menu
    app
    open-on-hover
    bottom
    offset-y
    max-width="40%"
    max-height="30%"
    min-width="220"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-alert
        v-bind="attrs"
        v-on="on"
        class="mb-0 mr-10"
        style="cursor: pointer"
        color="success"
      >
        <span>
          Выполняется операций:
          {{ queueList.filter((el) => !el.errorMessage).length }}.
        </span>
        <span v-if="withErrors">
          Завершилось ошибкой:
          <span class="red--text">
            {{ withErrors }}
          </span>
        </span>
      </v-alert>
    </template>

    <v-list v-if="queueList.length">
      <queue-item v-for="(item, idx) of queueList" :item="item" :key="idx" />
    </v-list>
  </v-menu>
</template>

<script>
import QueueItem from "@/layouts/components/QueueItem.vue";

export default {
  name: "QueueList",
  components: { QueueItem },
  props: {
    queueList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    withErrors() {
      return this.queueList.filter((el) => el.errorMessage).length;
    },
  },
};
</script>

export const DEFAULT_HEADERS_PRODUCT_LIST = [
  {
    text: "Выбор товара",
    value: "check",
    sortable: false,
    class: "d-none",
    cellClass: "d-none",
    isShow: true,
    width: "40px",
  },
  { text: "ID", value: "productId", sortable: false, isShow: true },
  {
    text: "Фото",
    value: "media",
    sortable: false,
    align: "center",
    isShow: true,
  },
  {
    text: "Название",
    value: "name",
    sortable: false,
    width: "250px",
    isShow: true,
  },
  // {
  //   text: "Категория",
  //   value: "category.name",
  //   sortable: false,
  //   width: "200px",
  //   isShow: true,
  // },
  {
    text: "Категория",
    value: "category",
    sortable: false,
    width: "200px",
    isShow: true,
  },
  {
    text: "Бренд",
    value: "vendor",
    sortable: false,
    width: "150px",
    isShow: true,
  },
  {
    text: "SKU",
    value: "skuCode",
    sortable: false,
    width: "200px",
    isShow: true,
  },
  {
    text: "Штрихкод",
    value: "barcode",
    sortable: false,
    width: "200px",
    isShow: true,
  },
  {
    text: "Сток",
    value: "quantityInStock",
    sortable: false,
    width: "100px",
    isShow: true,
  },
  {
    text: "Цена",
    value: "price",
    sortable: false,
    width: "70px",
    isShow: true,
  },
  {
    text: "Выгрузка в Wildberries",
    value: "wbRelations",
    sortable: false,
    isShow: true,
  },
  {
    text: "Выгрузка в Aliexpress",
    value: "aliRelations",
    sortable: false,
    isShow: true,
  },
  {
    text: "Синхронизация",
    value: "isSync",
    sortable: false,
    isShow: true,
    width: "60px",
  },
  {
    text: "Вес",
    value: "weight",
    sortable: false,
    isShow: true,
    width: "60px",
  },
  {
    text: "Габариты",
    value: "dimensions",
    sortable: false,
    isShow: true,
    width: "60px",
  },
  {
    text: "Склад",
    value: "warehouse",
    sortable: false,
    isShow: true,
    width: "80px",
  },
  {
    text: "Выгрузка в Yandex",
    value: "ymRelations",
    sortable: false,
    isShow: true,
  },
  {
    text: "Выгрузка в Ozon",
    value: "ozonRelations",
    sortable: false,
    isShow: true,
  },
  {
    text: "Источник",
    value: "createdByServiceId",
    sortable: false,
    isShow: true,
  },
];

<template>
  <v-list-item class="d-block pa-2 ma-2">
    <v-list-item-title class="text-left font-weight-bold">
      {{ item.title }}{{ item.total ? `(${item.total})` : "" }}
    </v-list-item-title>
    <v-list-item-content v-if="item.errorMessage" class="text-left py-2 my-1">
      <span>
        Ошибка:
        <span class="red--text">{{ item.errorMessage }}</span>
      </span>
    </v-list-item-content>
    <v-divider />
  </v-list-item>
</template>

<script>
export default {
  name: "QueueItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

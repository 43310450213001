import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";

import { createPinia, PiniaVuePlugin } from "pinia";

import titleComponent from "./components/Title/Title.vue";

Vue.use(PiniaVuePlugin);
Vue.component("vue-title", titleComponent);

Vue.config.productionTip = false;

const pinia = createPinia();

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
  pinia,
}).$mount("#app");

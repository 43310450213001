import { DEFAULT_HEADERS_PRODUCT_LIST } from "@/helper/productList/consts";
import { EMPTY_FILTER } from "@/components/Product/FilterDialog/helper";

const PREFIX_FEED_INFO_QUEUE_ID = "feed_info_queue_id";
const PREFIX_PRODUCT_FILTER = "product_filter";
const PREFIX_PRODUCT_TABLE_HEADER = "header_product_table";

const save = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const load = (key) => {
  const value = localStorage.getItem(key);
  if (!value) {
    return null;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    return null;
  }
};

const remove = (key) => {
  localStorage.removeItem(key);
};

export const saveAuthData = (data) => {
  save("auth", data);
};

export const loadAuthData = () => {
  return load("auth");
};

export const removeAuthData = () => {
  remove("auth");
  return true;
};

export const saveFeedInfoQueueId = (id, queueId) => {
  save(`${PREFIX_FEED_INFO_QUEUE_ID}_${id}`, queueId);
};

export const loadFeedInfoQueueId = (id) => {
  return load(`${PREFIX_FEED_INFO_QUEUE_ID}_${id}`);
};

export const removeFeedInfoQueueId = (id) => {
  remove(`${PREFIX_FEED_INFO_QUEUE_ID}_${id}`);
};

export const saveProductFilters = (storeId, filters) => {
  save(`${PREFIX_PRODUCT_FILTER}_${storeId}`, filters);
};

export const loadProductFilter = (storeId) => {
  let filters = load(`${PREFIX_PRODUCT_FILTER}_${storeId}`);
  if (!filters) {
    return JSON.parse(JSON.stringify(EMPTY_FILTER));
  }
  filters = { ...JSON.parse(JSON.stringify(EMPTY_FILTER)), ...filters };
  if (!filters.options.aliRelations) {
    filters.options.aliRelations = {
      ...JSON.parse(JSON.stringify(EMPTY_FILTER.options.aliRelations)),
    };
  }
  return filters;
};

export const saveProductTableHeader = (storeId, headers) => {
  save(`${PREFIX_PRODUCT_TABLE_HEADER}_${storeId}`, headers);
};

export const saveProductTableHeaderDefault = (headers) => {
  save(`${PREFIX_PRODUCT_TABLE_HEADER}_default`, headers);
};

export const loadProductTableHeader = (storeId) => {
  const headers = load(`${PREFIX_PRODUCT_TABLE_HEADER}_${storeId}`);
  if (!headers) {
    return (
      load(`${PREFIX_PRODUCT_TABLE_HEADER}_default`) ||
      DEFAULT_HEADERS_PRODUCT_LIST
    );
  }
  if (headers.length < DEFAULT_HEADERS_PRODUCT_LIST.length) {
    for (let i = headers.length; i < DEFAULT_HEADERS_PRODUCT_LIST.length; i++) {
      headers.push(DEFAULT_HEADERS_PRODUCT_LIST[i]);
    }
  }
  return headers;
};

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      layout: "empty-layout",
    },
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/store/list",
    name: "storeList",
    meta: {
      routeGroup: "store",
    },
    component: () => import("@/views/store/StoreList"),
  },
  {
    path: "/store/:storeId",
    name: "storeItem",
    meta: {
      routeGroup: "store",
    },
    component: () => import("@/views/store/StoreItem"),
  },
  {
    path: "/store/:storeId/services",
    name: "storeServices",
    meta: {
      routeGroup: "store",
    },
    component: () => import("@/views/store/StoreItem"),
  },
  {
    path: "/store/:storeId/provider/:providerId",
    name: "providerItem",
    meta: {
      routeGroup: "store",
    },
    component: () => import("@/views/store/StoreItem"),
  },
  {
    path: "/store/:storeId/feeds",
    name: "feeds",
    meta: {
      routeGroup: "store",
    },
    component: () => import("@/views/feed/FeedList"),
  },
  {
    path: "/store/:storeId/payments/:marketplace",
    name: "storePayments",
    meta: {
      routeGroup: "store",
    },
    component: () => import("@/views/payment/StorePayments"),
  },
  {
    path: "/store/:storeId/matching/:marketplace/category",
    name: "matchingWbCategory",
    meta: {
      routeGroup: "matching",
    },
    component: () => import("@/views/matching/category/Wb"),
  },
  {
    path: "/store/:storeId/matching/:marketplace/category/:categoryId/attribute",
    name: "matchingAttributes",
    component: () => import("@/views/matching/attribute/MatchingAttribute"),
  },
  {
    path: "/user",
    name: "userTable",
    meta: {
      routeGroup: "user",
    },
    component: () => import("@/components/User/UserTable"),
  },
  {
    path: "/log",
    name: "logActionList",
    meta: {
      routeGroup: "log",
    },
    component: () => import("@/views/log/LogActionList"),
  },
  {
    path: "/payment",
    name: "paymentList",
    meta: {
      routeGroup: "payment",
    },
    component: () => import("@/views/payment/PaymentList"),
  },
  {
    path: "/store/:storeId/provider/:providerId/feed/:id/edit",
    name: "editFeed",
    component: () => import("@/views/feed/FeedEdit"),
  },
  {
    path: "/store/:storeId/products/:field/:value",
    name: "productView",
    component: () => import("@/views/product/ProductView"),
  },
  {
    path: "/store/:storeId/products",
    name: "productList",
    component: () => import("@/views/product/ProductList"),
  },
  {
    path: "/store/:storeId/discount/:marketplace/list",
    name: "discountList",
    component: () => import("@/views/discount/DiscountList"),
  },

  // Статистика
  {
    path: "/statistic",
    name: "statistic",
    component: () => import("@/views/statistic/StatisticList"),
  },

  {
    path: "/statistic/:marketplace/store/:storeId/:serviceStoreId",
    name: "statisticStore",
    component: () => import("@/views/statistic/StatisticStoreView"),
  },

  // Статистика
  {
    path: "/indicators",
    name: "indicators",
    component: () => import("@/views/indicators/IndicatorsList"),
  },

  {
    path: "/indicators/:marketplace/store/:storeId/:serviceStoreId",
    name: "indicatorsStore",
    component: () => import("@/views/indicators/IndicatorsStoreView"),
  },

  {
    path: "/store/:storeId/config",
    name: "storeConfig",
    component: () => import("@/views/config/StoreConfig"),
  },

  {
    path: "/automatching",
    name: "automatching",
    component: () => import("@/views/automatching/Automatching"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
